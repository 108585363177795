<template>
  <b10-list-item
    :item="formattedItem"
  >
    <v-list-item-content>
      <v-list-item-title
        v-html="formattedItem.title"
      />
      <v-list-item-subtitle
        class="break-word"
        v-html="formattedItem.subtitle"
      />
      <v-list-item-subtitle>
        <v-chip
          color="info"
          dark
          small
          label
          class="mr-1"
        >
          {{ formattedItem.unidades }} {{ 'unidad'|pluralize(formattedItem.unidades, 'es') }}
        </v-chip>
      </v-list-item-subtitle>
    </v-list-item-content>
    <v-list-item-action
      v-if="!formattedItem.obligatorio"
    >
      <v-btn
        icon
        @click.stop="clickDelete"
      >
        <v-icon>{{ $vuetify.icons.values.delete }}</v-icon>
      </v-btn>
    </v-list-item-action>
  </b10-list-item>
</template>

<script>
import _ from '@/utils/lodash'
import { nonEmpty } from '@/utils/templates'

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
    index: {
      type: Number,
      default: 0,
    },
  },
  computed: {
    formattedItem () {
      const item = _.cloneDeep(this.item)
      item.title = this.$options.filters.linebreaksBr(nonEmpty`${item.lorden_trabajo_detalle.descripcion} (${item.articulo.codigo})`)
      item.subtitle = item.lorden_trabajo_detalle.observacion
      item.unidades = item.lorden_trabajo_detalle.unidades
      item.obligatorio = item.articulo_escandallo ? item.obligatorio = item.articulo_escandallo.obligatorio : false
      return item
    }
  },
  methods: {
    clickDelete () {
      this.$emit('click-delete', { item: this.item, index: this.index })
    }
  },
}
</script>
